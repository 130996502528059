@import "src/styles/variables";

.messages--body-empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 24px;
  padding-right: 5px;

  .empty-img {
    display: inline-flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 8px;

    img {
      display: inline-flex;
      width: 100%;
      max-width: 395px;
    }
  }

  .empty-subtitle {
    @include font_sh_1($primary-80);
    text-align: center;
    word-break: break-word;
  }
}

