@import '../../styles/variables';

.links-in {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: var(--app-height);
  background-image: url('../../../public/assets/images/auth-bg.jpg');
  background-size: cover;
  gap: 16px;
  padding: 24px;
  box-sizing: border-box;

  &--title {
    @include font_T1;
    text-align: center;
    max-width: $mediaWidthMD;
  };

  &--body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
}
