@import 'src/styles/variables';

.expert-rates {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;

  &--header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    flex-wrap: wrap;
    padding-bottom: 8px;
    border-bottom: 1px solid $primary-10;

    .header-title {
      @include font_T3_bold($blue-100);
      line-height: 31px;
    }
  }

  &.full-height-page {
    height: 100%;
  }

  &--empty {
    margin-top: 46px;
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;

    .empty-img {
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;

      img {
        display: inline-flex;
        width: 100%;
        max-width: 460px;
      }
    }

    .empty-subtitle {
      margin-top: 24px;
      @include font_sh_1($primary-80);
    }
  }

  &--label {
    @include font_T3_bold;
  }

  &--list {
    display: flex;
    width: 100%;

    .rates-list-item {
      @media (max-width: $mediaWidthMD) {
        &:empty {
          display: none;
        }
      }
    }

    @media (max-width: $mediaWidthMD) {

      & {
        flex-direction: column;
      }
    }
  }
}

