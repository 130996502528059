@import 'src/styles/variables';

.conversation-consultation {
  padding: 16px;
  box-sizing: border-box;
  border-radius: 12px;
  background-color: $primary-05;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease;

  &:not(.empty-messages).show-active {
    background-color: $orange-05 !important;
  }

  &.empty-messages.show-active {
    .conversation-consultation--header {
      background-color: $orange-05 !important;
    }
  }

  &.empty-messages {
    padding: 0;
    background-color: $white;

    .conversation-consultation--header {
      background-color: $primary-05;
      margin-bottom: 0;
    }
  }

  &--header {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    padding: 8px 16px;
    background-color: $white;
    border-radius: 32px;
    transition: all 0.3s ease;

    .header-item {
      display: inline-flex;
      align-items: center;
      @include font_P2_med;

      &.has-divider::after {
        content: '|';
        font-size: 12px;
        width: 16px;
        text-align: center;
        margin: 0 4px;
      }

      .react-custom-icon {
        display: inline-flex;
        vertical-align: middle;
        margin-right: 4px;
        color: $orange-100 !important;
      }
    }

    .header-item:nth-child(1) {
      @include font_P3_reg($primary-80);
      margin-right: 16px;
    }

    .header-item:nth-child(2) {
      @include font_P2_semibold;
      text-transform: capitalize;
    }
  }

  &--chat {
    width: 100%;

    .message-list {
      &--item {
        max-width: 552px;

        @media (min-width: $mediaWidthXLL) {
          max-width: 70%;
        }
      }
    }
  }

  &--join {
    width: 100%;
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;

    .join-message {
      @include font_P3_reg;
      text-align: center;

      .ant-statistic {
        display: inline-flex;

        .ant-statistic-content {
          display: inline-flex;

          &-value {
            @include font_P2_semibold($red-100);
          }
        }
      }
    }
  }

  @media (max-width: $mediaWidthXL) {
    &--header {
      flex-wrap: wrap;
      justify-content: center;
    }
  }

  @media (max-width: $mediaWidthXS) {
    &--header {
      .header-item:nth-child(2) {
        margin-right: 8px;

        &:after {
          content: '';
        }
      }
    }
  }
}
