@import '../../../../styles/variables';

.tu-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  @include font_T1;

  .title-steps {
    @include font_P1_med;

    span {
      @include font_P1_med($orange-100);
    }
  }
}
