@import '../../../../styles/variables';

.links-in-tu-log-in {
  .auth-inside {
    display: flex;
    flex-direction: column;

    .auth-inside-item {

      &.left-section {
        width: 100%;

        .forms-section {
          padding-right: 0;

          .card-section {
            border: none;
            padding: 0;

            .card-section-title {
              @include font_F3;
              justify-content: center;
              margin-bottom: 38px;
            }

            .forgot-password-link {
              align-self: flex-start;
            }
          }

          .logo {
            display: none;
          }
        }
      }

      &.right-section {
        display: none;
      }
    }
  }
}
