@import 'src/styles/variables';

.failed-status {
  display: flex;
  flex-direction: column;
  gap: 12px;

  &--title {
    @include font_P1_med;
    display: flex;
    align-items: center;
    gap: 8px;

    .react-custom-icon {
      color: $red-100 !important;
    }
  }

  &--content {
    @include font_P2_reg;

    b {
      @include font_P2_semibold;
    }

    .ant-statistic {
      display: inline-flex;

      .ant-statistic-content {
        @include font_P2_semibold($red-100);
      }
    }
  }
}
