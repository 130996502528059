@import '../../../../styles/variables';

.auth-inside {
  display: flex;
  flex-direction: row;
  flex: 1 0 auto;
  justify-content: center;
  align-items: center;

  .card-section-image {
    margin: 16px 0 16px 0;
  }

  @media screen and (max-width: $mediaWidthXS) {
    padding-bottom: 120px;
    height: calc(var(--app-height) - 120px);
  }

  &-item {
    width: 50%;
    height: 100%;
    align-items: center;
    display: flex;

    &.left-section {
      justify-content: flex-end;

      @media screen and (max-width: $mediaWidthLG) {
        justify-content: center;

        .field-container {
          .input-code-elements {
            .ant-input[disabled] {
              background-color: $primary-05 !important;
              border: 1px solid $primary-20 !important;
            }
          }
        }
      }
    }

    &:last-child {
      @media screen and (max-width: $mediaWidthLG) {
        display: none;
      }
    }

    .forms-section {
      padding-right: 104px;

      @media screen and (max-width: $mediaWidthX) {
        padding-right: 72px;
      }
      @media screen and (max-width: $mediaWidthLG) {
        width: 100%;
        max-width: 394px;
        padding-right: 0;
      }
    }

    .form-bottom {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      margin-top: 24px;

      .actions {
        justify-content: flex-start !important;

        .ant-btn:last-child {
          margin-left: auto;
        }
      }

      .forgot-password-link {
        display: inline-block;
        align-self: flex-end;

        @media (max-width: $mediaWidthLG) {
          align-self: flex-start;
        }
      }

      a {
        @include font_P1_reg($blue-100);
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    .logo {
      margin-left: 24px;
      margin-bottom: 12px;

      @media screen and (max-width: $mediaWidthLG) {
        display: none;
      }

      @media screen and (min-width: $mediaWidthXLL) {
        width: 164px;
      }

      @media screen and (min-width: $mediaWidthMax) {
        width: 182px;
      }
    }
  }

  &-item.right-section {
    img {
      height: 418px;
      width: auto;
    }

    img, .react-custom-icon-big svg {
      @media screen and (max-width: $mediaWidthX) {
        height: 350px;
        width: auto;
      }

      @media screen and (max-width: $mediaWidthXS) {
        height: 180px;
        margin: 24px 0;
      }
    }
  }

  .auth-spinner {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 20;

    .spin-element {
      margin: 0;
      height: calc(100% - 34px);

      @media screen and (min-width: $mediaWidthXLL) {
        height: calc(100% - 37px);
      }

      @media screen and (min-width: $mediaWidthMax) {
        height: calc(100% - 40px);
      }

      @media screen and (max-width: $mediaWidthXS) {
        height: calc(100% - 120px);
      }
    }
  }

  @media (max-width: $mediaWidthLG) {
    box-sizing: border-box;
    flex-direction: column-reverse;
    height: 100%;
    width: 100%;
    justify-content: flex-end;
    padding-bottom: 34px;

    .auth-inside-item {
      width: 100%;

      &.right-section {
        display: none;
      }

      .forms-section {
        .logo {
          display: none;
        }

        .card-section-title {
          margin-bottom: 30px;
        }

        .ant-input-affix-wrapper {
          background-color: $white !important;
        }

        .form-bottom-block {
          display: flex;

          .info-text {
            margin-bottom: 0;
            margin-left: 0;
            display: flex;
            flex-direction: column;
          }
        }
      }
    }
  }

  @media (max-width: $mediaWidthXS) {
    padding-bottom: 120px;
  }
}
