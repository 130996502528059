@import 'src/styles/variables';

.payment-failed {
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  z-index: 2;
  border: 1px solid $primary-10;
  background-color: $primary-05;
  padding: 16px;
  box-sizing: border-box;
  border-radius: 12px;
  max-width: 395px;
  min-width: 395px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-height: calc(var(--app-height) - 159px);
  transition: all 0.3s ease;

  @media screen and (max-width: $mediaWidthMD) {
    max-height: calc(var(--app-height) - 192px);
  }

  @media screen and (max-width: $mediaWidthXS) {
    min-width: unset;
  }

  &--body {
    overflow: hidden;
    overflow-y: auto;
    padding-right: 4px;
  }

  .payment-form {
    .payment-form--item {
      &.item-actions {
        gap: 24px;
        justify-content: space-between;

        .ant-btn {
          min-width: unset;
        }
      }
    }
  }

  .form-provider--item {
    .item-actions {
      justify-content: space-between;
  
      button {
        min-width: auto;
      }
    }
  }
}
