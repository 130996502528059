@import "src/styles/variables";

.messages--body-input {
  position: relative;
  margin-top: 24px;

  .input-item {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;

    &:last-child {
      margin-bottom: 0;
      display: flex;
      justify-content: flex-end;
    }

    &--files {
      @include font_F2($primary-40);
      position: absolute;
      left: 45px;
      bottom: -20px;
    }

    &--price {
      @include font_B1;
      @include lineClamp(1);
      word-break: break-all;
      margin-right: 4px;
      width: 100%;
    }

    &:first-child {
      margin-bottom: 54px;

      .react-custom-icon {
        cursor: pointer;
        margin-right: 8px;
      }
    }

    .ant-btn {
      .react-custom-icon {
        color: $white !important;
      }
    }

    .ant-input-affix-wrapper {
      border: none !important;
      box-shadow: none !important;
      outline: none !important;

      textarea {
        @include font_F1;
        border: 1px solid $primary-20 !important;
        border-radius: 12px !important;
        resize: none !important;
        padding: 15px 16px;
        height: 124px;

        &::placeholder {
          color: $primary-60 !important;
        }

        &::-webkit-scrollbar {
          width: 4px;
          border-radius: 2px;
          background-color: transparent;
        }

        &::-webkit-scrollbar-track {
          margin: 10px 0;
        }

        &::-webkit-scrollbar-thumb {
          background-color: $primary-80;
          width: 4px;
          border: 1px solid $primary-80;
          border-radius: 2px;
        }
      }

      &-focused {
        border: none !important;
        box-shadow: none !important;
        outline: none !important;

        textarea {
          border: 1px solid $primary-100 !important;
        }
      }
    }

    .ant-input-data-count {
      @include font_F2($primary-40);
      margin-right: 40px;
    }

    @media (max-width: $mediaWidthMD) {
      & {
        justify-content: flex-end;
      }
      &:first-child {
        margin-bottom: 50px;
      }
      .input-item--price {
        display: none;
      }

      textarea, textarea::placeholder {
        font-size: 15px;
      }
    }

    @media (max-width: 340px) {
      .input-item--files {
        max-width: 120px;
        bottom: -40px;
      }
    }
  }
}
