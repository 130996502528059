@import 'src/styles/variables';

.success-checkout {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;

  &--subtitle {
    @include font_P1_reg;
    text-align: center;
  }

  &--icon {
    display: inline-flex;

    .react-custom-icon {
      width: 88px !important;
      height: 88px !important;
      color: $green-100 !important;

      svg {
        width: 88px !important;
        height: 88px !important;

        path {
          fill: $green-100 !important;
        }
      }
    }
  }

  &--type {
    @include font_Sum4;
    display: flex;
    align-items: center;
    gap: 4px;
    margin-top: 20px;

    .ant-tooltip--wrapper {
      @include lineClamp(1);
      word-break: break-all;
    }
  }

  &--with {
    @include font_P2_reg($primary-80);
  }

  &--date {
    @include font_Sum4;
  }

  &--cost {
    @include font_Sum1($primary-80);
    margin-top: 14px;
    padding: 4px 16px 4px 16px;
    box-sizing: border-box;
    text-align: center;
    background-color: $primary-05;
    border-radius: 1px;
  }

  &--reminder {
    margin-top: 14px;
    word-break: break-word;
  }
}
