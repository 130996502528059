.form-bottom.form-bottom-block {
  display: flex;
  flex-direction: column;
  gap: 24px;

  .actions {
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 4px;
  }
}
