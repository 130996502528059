@import '../../../../styles/variables';

.call-notification-page {
  position: absolute;
  z-index: 9;
  background-color: $call-bg;
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .call-notification-page-inside {
    max-width: 394px;
    padding: 0 15px;
    text-align: center;

    @media screen and (min-width: $mediaWidthXLL) {
      max-width: 425px;
    }

    @media screen and (min-width: $mediaWidthMax) {
      max-width: 485px;
    }

    .notification-title {
      @include font_H1($white);
      word-break: break-word;
    }

    .notification-text {
      @include font_P1_reg($primary-20);
      word-break: break-word;
      margin: 24px 0;
    }

    .actions {
      display: flex;
      justify-content: center;
      column-gap: 24px;
    }

    .ant-btn.ant-btn-lg {
      height: 36px;
      min-width: 56px;
    }
  }
}
