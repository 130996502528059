@import 'src/styles/variables';

.payment-failed-reminder-countdown {
  @include font_T2($red-100);
  display: inline-flex;
  align-items: center;
  gap: 4px;

  &::before {
    content: '';
    margin: 0 20px;
    width: 1px;
    height: 22px;
    background-color: $primary-60;

    @media screen and (max-width: $mediaWidthXS) {
      margin: 0 12px;
    }
  }

  .ant-statistic {
    .ant-statistic-content {
      display: inline-flex;

      .ant-statistic-content-value {
        @include font_T2($red-100);
      }
    }
  }
}
