@import 'src/styles/variables';

.paypal-footer {
  flex: 1 0 auto;
  display: flex;
  align-items: flex-end;
  margin-top: 16px;

  &-dark {
    .paypal-footer-item {
      color: $white;
    }
    .paypal-footer-item--divider {
       background-color: $primary-60;
     }
  }

  &-item {
    @include font_P3_reg($primary-60);
    margin-right: 12px;

    a {
      color: inherit;
      text-decoration: none;
    }

    .bold-text {
      font-weight: 700;
      font-size: 14px;
    }

    &:first-child, &:last-child {
      margin-right: 0;
    }

    &--divider {
      margin: 0 24px;
      height: 20px;
      width: 1px;
      background-color: $primary-20;
    }
  }
}
