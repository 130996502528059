@import '../../../styles/variables';

.call-finished {
  height: 100vh;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $call-bg;

  &-inside {
    padding: 0 24px;
    max-width: 394px;
    width: 100%;
    box-sizing: border-box;

    .title {
      @include font_H1($white);
      word-break: break-word;
      text-align: center;
      margin-bottom: 24px;
    }

    .call-with {
      @include font_T2($white);
      word-break: break-word;
      text-align: center;
      margin-bottom: 8px;
    }

    .call-result-info {
      padding: 8px 24px;
      display: flex;
      justify-content: center;
      align-items: center;

      .react-custom-icon {
        margin-right: 8px;
      }

      .call-divider {
        width: 1px;
        height: 24px;
        background-color: $primary-60;
        margin: 0 20px;
      }

      .call-result-text {
        @include font_P1_med($white);
      }
    }

    .actions-section {
      text-align: center;
      margin-top: 24px;
    }
  }

  .power-by {
    display: inline-flex;
    position: absolute;
    bottom: 24px;
    text-align: center;

    &-inside {
      display: inline-flex;
      flex-direction: column;
      margin: 0 auto;
      max-width: 394px;
      cursor: pointer;

      .text {
        margin-bottom: 4px;
        @include font_P5_reg($white);
      }

      img {
        height: 18px;

        @media screen and (min-width: $mediaWidthXLL) {
          height: 20px;
        }
        @media screen and (min-width: $mediaWidthMax) {
          height: 23px;
        }
      }
    }
  }
}
