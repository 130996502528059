@import "src/styles/variables";

.card-fields--form {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;

  .form-row {
    &.flex-items {
      display: flex;
      align-items: flex-start;
      gap: 0.75rem;
    }

    &--item {
      display: flex;
      flex-direction: column;
      width: 100%;

      div {
        width: 100%;
        display: inline-flex;
      }

      .error-hint {
        @include font_P2_reg(#df1b41);
        max-height: 0;
        opacity: 0;
        overflow: hidden;
        transition: all 0.3s ease-in-out;

        &:not(:empty) {
          max-height: 50px;
          opacity: 1;
        }
      }
    }
  }
}
