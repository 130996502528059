@import 'src/styles/variables';

.form-provider {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  &--item {
    //&:nth-child(2) {
      height: 100%;

      div[style="width: 100%;"] {
        height: 100%;
      }
    //}
  }
}
