@import "src/styles/variables";

.notification-item {
  display: flex;
  align-items: center;
  border-radius: 1px;
  box-sizing: border-box;
  padding: 16px 24px;
  background-color: $primary-02;
  margin-bottom: 0;
  align-self: flex-start;

  &.notification-item--unread {
    background-color: $orange-10;
  }

  &--info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-right: 16px;

    .info-item {
      &--title {
        @include font_P3_reg;
        word-break: break-word;

        span {
          margin-right: 4px;

          &.bold-text {
            margin-right: 0;
            @include font_P3_semibold;
          }
        }
      }

      &--date {
        display: inline-flex;
        align-items: center;
        flex-wrap: wrap;
        margin-top: 8px;
        @include font_P4_reg($primary-60);

        .date-item {
          white-space: nowrap;
        }

        .logo-item {
          display: inline-flex;
          align-items: center;
          @include font_P4_med($blue-100);
          font-size: 12px !important;
          margin-right: 16px;

          .react-custom-icon {
            margin-right: 4px;
            color: $blue-100 !important;
          }
        }
      }
    }
  }

  &--actions {
    display: flex;
    align-items: center;

    .actions-item {
      &--view {
        margin-right: 16px;
      }
    }

    @media (max-width: $mediaWidthXS) {
      flex-direction: column-reverse;
      .actions-item {
        &--view {
          margin-right: 0;
        }

        &--delete {
          margin-bottom: 8px;
          align-self: flex-end;

          .react-custom-icon {
            color: $primary-100 !important;
          }
        }
      }
    }
  }
}
