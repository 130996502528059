@import '../../../styles/variables';

.tu-first-step {

  .tu-first-step-form--redirect {
    @include font_P1_reg($primary-80);
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 24px;

    span.redirect-action {
      @include font_P1_reg($blue-100);
      cursor: pointer;
    }
  }

  .link-to-sign-in {
    margin-top: 44px;
    margin-left: 16px;
    @include font_B1();

    a {
      color: $blue-100;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .tu-footer {
    margin-top: 24px;
    display: flex;
    justify-content: space-between;
  }
}
