@import 'src/styles/variables';

.rates-list-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 0 24px;
  border-right: 1px dashed $primary-10;
  gap: 16px;

  &:empty:not(.has-service) {
    display: none;
  }

  &:first-child {
    padding-left: 0;
  }

  &:last-child {
    border: none;
    padding-right: 0;
  }

  .item-icon {
    .react-custom-icon {
      color: $orange-100 !important;
    }
  }

  .item-name {
    @include font_P3_semibold($primary-80);
    @include lineClamp(1);
    word-break: break-all;
    margin-bottom: 16px;
    text-align: center;
  }

  .item-type {
    @include font_P2_reg($blue-100);
    text-align: center;
  }

  .item-rate {
    @include font_T3_bold($primary-80);
    text-align: center;

    span {
      margin-left: 0.25rem;
      @include font_P3_reg($primary-60);
    }
  }

  .item-cost {
    @include font_P2_reg($blue-100);
    text-align: center;
  }

  .item-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    &--item {
      @include font_P3_reg($primary-60);
      margin-bottom: 16px;

      &:last-child {
        margin-bottom: 0;
      }

      b {
        @include font_T3_bold($primary-80);
      }

      span {
        @include font_P3_reg($primary-60);
      }
    }
  }

  @media (max-width: $mediaWidthMD) {

    & {
      width: auto;
      border-right: none;
      margin-right: 0;
      padding: 0 0 16px 0;

      border-bottom: 1px dashed $primary-10;
      margin-bottom: 16px;

      &:last-child {
        border: none;
      }
    }
  }
}
