@import 'src/styles/variables';

.ant-drawer.transactions-filters-modal {
  .ant-drawer-content-wrapper {
    width: 100vw !important;
    max-width: 628px;

    .ant-drawer-content .ant-drawer-wrapper-body, .ant-drawer-content {
      .ant-drawer-header {
        border-bottom: none;
        padding: 24px;

        &-title {
          .ant-drawer-close {
            order: 1;
            margin-right: 0;
            margin-left: 12px;

            .react-custom-icon {
              color: $primary-100 !important;
            }
          }

          .ant-drawer-title {
            @include font_T1;
          }
        }
      }

      .ant-drawer-body {
        padding: 0 24px 24px 24px;

        .transactions-filters-modal--body {
          height: 100%;
          display: flex;
          flex-direction: column;

          .body-item {
            display: flex;
            flex-direction: column;
            margin-bottom: 24px;

            label.custom-checkbox {
              span:not(.ant-checkbox) {
                @include lineClamp(1);
                word-break: break-all;
                white-space: break-spaces;
                max-width: 400px;
              }
            }

            .checkbox-group-element {
              display: flex;
              flex-direction: row;
              flex-wrap: wrap;
              gap: 12px 0;

              .ant-checkbox-wrapper {
                width: 50%;
                margin-inline-start: unset;
                line-height: 24px !important;
              }

              @media (max-width: $mediaWidthXS) {
                flex-direction: column;
              }
            }

            &--label {
              @include font_T3_bold;
              margin-bottom: 8px;
            }

            &:last-child {
              margin-bottom: 16px;
            }

            .ant-form-item {
              margin-bottom: 0;
            }
          }
        }
      }

      .ant-drawer-footer {
        display: flex;
        justify-content: space-between;
        padding: 24px;
        border-top: none !important;

        .ant-btn:first-child {
          margin-right: 8px;
        }
      }
    }
  }
}
