@import "src/styles/variables";

.transactions-table {
  height: var(--app-height);
  display: flex;
  flex-direction: column;

  .ant-table {
    max-height: calc(var(--app-height) - 140px - var(--outgoingHeight));
    overflow-y: auto;
  }

  &.transactions-table-expert {
    height: 100%;

    .ant-table-wrapper {
      td {
        height: 85px;
      }
    }

    .ant-table {
      max-height: calc(var(--app-height) - 140px - 90px - var(--outgoingHeight));
      overflow-y: auto;

      @media (max-width: 1210px) {
        max-height: calc(var(--app-height) - 140px - 90px - 30px - var(--outgoingHeight));
      }
    }
  }

  .ant-table-wrapper {

    td.ant-table-column-sort {
      background: transparent !important;
    }

    .ant-spin:not(.actions-spin) {
      position: fixed;
      max-height: unset;
      right: 0;
      inset-inline-start: unset;
      height: var(--app-height);
      width: calc(100vw - 110px);
    }

    .ant-pagination {
      margin: 16px 0 8px 0;

      .ant-pagination-prev, .ant-pagination-next {
        .ant-pagination-item-link {
          color: $orange-100 !important;
        }

        &.ant-pagination-disabled {
          .ant-pagination-item-link {
            color: $primary-15 !important;
          }
        }
      }

      .ant-pagination-item-active {
        background-color: $primary-10;
        border-color: $primary-10;
      }
    }

    .ant-table-column-sorter-up.active, .ant-table-column-sorter-down.active {
      color: $orange-100 !important;
    }

    .ant-table-thead {
      .ant-table-cell.counsellor {
        .ant-table-column-title {
          @include lineClamp(1);
          word-break: break-all;
          max-width: 90%;
        }
      }
    }

    .ant-table-tbody {
      .ant-table-row td {
        border-bottom: none;
        padding: 16px 16px;

        @media screen and (min-width: $mediaWidthXLL) {
          padding: 14px 16px;
        }
        @media screen and (min-width: $mediaWidthMax) {
          padding: 10px 16px;
        }
      }

      .ant-table-row:nth-child(even) td {
        background-color: $primary-02;

        &.ant-table-column-sort {
          background-color: $primary-02 !important;
        }
      }

      .ant-table-row:nth-child(odd):hover td {
        background-color: $white;
      }
    }

    .ant-table-cell {
      &:not(th).counsellor .counsellor--body {
        display: flex;

        .body-avatar {
          margin-right: 16px;
        }

        .body-info {
          display: inline-flex;
          flex-direction: column;

          &--name {
            @include font_P1_reg;
            @include lineClamp(1);
            color: $blue-100;

            &:hover {
              text-decoration: underline;
            }
          }

          .list-ellipsis {
            @include font_P4_reg($primary-60);
          }
        }

        @media (max-width: 1279.98px) {
          .body-avatar {
            display: none;
          }
        }
      }

      &:not(th).invoice {
        .ant-spin-spinning.loader-item {
          position: relative;
          width: 24px;
          height: 24px;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .react-custom-icon {
          cursor: pointer;
        }
      }

      &:not(td).date {
        .ant-table-column-sorters {
          justify-content: flex-start;
          padding-left: 12px;
        }
      }

      &:not(th).date .date--body {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .body-item {
          @include font_P3_semibold($primary-80);
        }
      }

      &.details {
        text-align: center;
      }

      &:not(th).details .details--body {
        .body--item {
          @include font_P3_reg($primary-80);
          display: inline-flex;
          align-items: center;
          justify-content: center;
          flex-wrap: wrap;

          span {
            display: inline-flex;

            &:after {
              content: ',';
              margin-right: 2px;
            }

            &:last-child, &:first-child {
              &::after {
                content: none;
              }
            }

            .react-custom-icon {
              color: $orange-100 !important;
              display: inline-flex;
              margin-right: 2px;
            }
          }

          .bold-text {
            @include font_P3_semibold($primary-80);
          }
        }
      }

      &.cost {
        text-align: center;
      }

      &:not(th).cost {
        @include font_T4_sb($primary-80);
      }

      &:not(th).type .type--body {
        display: flex;
        white-space: nowrap;

        .react-custom-icon {
          color: $orange-100 !important;
          margin-right: 8px;
        }
      }

      &:not(td).actions {
        padding-right: 12px;
      }

      &:not(th).actions .actions--body {
        display: flex;
        align-items: center;
        justify-content: center;

        .body--item {
          display: inline-flex;
          align-items: center;
          justify-content: center;

          .ant-spin.actions-spin {
            display: inline-flex;
            align-items: center;
            height: 24px;
          }

          .ant-dropdown-trigger {
            display: inline-flex;
            cursor: pointer;
          }

          .react-custom-icon {
            cursor: pointer;
          }

          &.accepted .react-custom-icon {
            color: $green-100 !important;
          }

          &.declined .react-custom-icon {
            color: $red-100 !important;
          }

          &.requested .react-custom-icon {
            color: $orange-100 !important;
          }

          &.rolling .react-custom-icon {
            color: $orange-100 !important;
          }

          &.actions {
            cursor: pointer;
          }
        }
      }

      &:not(th).invoice {
        .react-custom-icon {
          color: $blue-100 !important;
        }
      }
    }
  }

  &--header {
    display: flex;
    align-items: center;
    padding: 16px 24px 16px 24px;

    .header-title {
      @include font_H1;
      flex: 1 0 auto;

      &.small-title {
        @include font_T3_bold;
      }
    }

    .header-search {
      width: 100%;
      max-width: 290px;
      margin-right: 24px;
    }

    .header-filters {
      position: relative;
      display: inline-flex;
      cursor: pointer;

      &.has-filters::after {
        content: '';
        width: 5px;
        height: 5px;
        border-radius: 50%;
        position: absolute;
        top: 0;
        right: 0;
        background-color: $orange-100;
      }

      .react-custom-icon {
        color: $primary-100 !important;
      }
    }
  }

  &--subtitle {
    @include font_P1_med($primary-80);
    margin-top: -15px;
    padding: 0 24px 8px 24px;
  }

  &--img {
    width: 100%;
    flex: 1 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      display: inline-flex;
      width: 100%;
      max-width: 450px;
    }
  }

  &--body {
    padding: 0 24px 8px 24px;
  }
}

.refund-menu-item {
  display: flex;
  align-items: center;
  @include font_P2_reg;

  .react-custom-icon {
    margin-right: 8px;
    color: $primary-100 !important;
  }
}
