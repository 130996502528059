@import 'src/styles/variables';

.payment-form-paypal {
  max-width: 471px;
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;

  &-dark {
    .payment-form--item {
      &.item-divider {
        color: $primary-20;
      }
    }
  }

  &--error {
    .info-message {
      display: inline-flex;
    }
  }

  &--item {
    margin-bottom: 14px;

    .ant-form-item {
      display: inline-flex;
      margin-bottom: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }

    .ant-radio-group {
      flex-direction: row !important;
    }

    .ant-checkbox-wrapper {
      @include font_P2_reg($primary-80);
    }

    &.item-divider {
      @include font_P2_reg($primary-60);
      display: flex;
      align-items: center;
      width: 100%;
      word-break: normal;
      padding: 10px 0;

      &::before, &::after {
        content: '';
        height: 1px;
        flex: 1 0 auto;
        background: $primary-20;
        display: block;
      }

      &::before {
        margin-right: 16px;
      }

      &::after {
        margin-left: 16px;
      }
    }

    &.item-card-list {
      margin-bottom: 35px;

      .ant-form-item, .ant-row, .form-field-wrapper {
        width: 100%;
        max-width: 100% !important;
      }

      .dropdown-element {
        margin: 0;
        max-width: 230px;
      }
    }

    &.item-payment-element {

    }

    &.item-actions {
      display: flex;
      flex: 1 0 auto;
      justify-content: flex-end;
      align-items: flex-end;
      margin-top: 20px;

      button {
        min-width: 226px;
        justify-content: center;

        &[disabled].dark {
          background-color: $primary-10 !important;
          color: $primary-40 !important;
        }
      }
    }
  }
}
