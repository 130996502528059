@import 'src/styles/variables';

.book-appointment-checkout-trigger {
  &--checkout-info {
    display: flex;
    flex-direction: column;
    gap: 4px;

    .checkout-info--title {
      @include font_Sum4;
      display: flex;
      align-items: center;
      gap: 4px;

      .ant-tooltip--wrapper {
        @include lineClamp(1);
        word-break: break-all;
      }
    }

    .checkout-info--subtitle {
      @include font_P2_reg($primary-80);
    }

    .checkout-info--date {
      @include font_Sum4;
    }

    .checkout-info--cost {
      @include font_Sum1($primary-80);
      margin-top: 14px;
      padding: 4px 16px 4px 16px;
      box-sizing: border-box;
      text-align: center;
      background-color: $primary-05;
      border-radius: 1px;
    }

    .checkout-info--reminder {
      margin-top: 14px;
      word-break: break-word;
    }

    .checkout-info--footer {
      margin-top: 14px;
      flex: 1 0 auto;
      display: flex;
      align-items: flex-end;
    }
  }
}

