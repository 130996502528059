@import 'src/styles/variables';

.consultation-info {
  display: flex;
  flex-direction: column;

  &-dark {
    .consultation-info--title {
      @include lineClamp(1);
      word-break: break-all;
      color: $white;

      .react-custom-icon {
        color: $white !important;
        display: inline-flex;
        vertical-align: middle;
      }
    }

    .consultation-info--subtitle {
      color: $primary-10;
    }

    .consultation-info--price {
      background-color: $primary-80;
      color: $white;

      .bold-text {
        color: $white;
        margin-right: 4px;
      }
    }

    .consultation-info--price-per-session {
      background-color: $primary-80;
      color: $white;

      .price-per-session-icon {
        svg {
          color: $primary-40;
        }
      }

      .price-per-session-info {
        flex: 1 0 auto;
        @include font_P1_reg($white);

        span {
          @include font_Sum1($white);
        }

        span.tooltip-span {
          position: relative;

          .ant-tooltip--wrapper {
            display: inline-flex;
            position: absolute;
            top: 0px;
            right: -10px;

            .react-custom-icon {
              display: inline-flex;
              color: $white !important;
            }
          }
        }

        @media (max-width: 420px) {
          span {
            @include font_Sum4($white);
          }
        }
      }
    }
  }

  &--title {
    @include font_Sum4;
    display: flex;
    align-items: center;
    margin-bottom: 4px;

    .react-custom-icon {
      color: $primary-100 !important;
      margin-right: 8px;
    }
  }

  &--subtitle {
    @include font_P2_reg($primary-80);
    margin-bottom: 16px;
  }

  &--price {
    @include font_P1_reg($primary-80);
    text-align: center;
    margin-bottom: 16px;
    padding: 4px 16px;
    background-color: $primary-05;
    border-radius: 1px;

    .bold-text {
      @include font_Sum1($primary-80);
      margin-right: 4px;

      @media (max-width: 420px) {
        @include font_Sum4($primary-80);
      }
    }
  }

  &--reminder {

  }

  &--footer {
    flex: 1 0 auto;
    display: flex;
    align-items: flex-end;
  }

  &--price-per-session {
    display: flex;
    align-items: center;
    background-color: $primary-05;
    padding: 4px 12px;
    border-radius: 1px;
    margin-bottom: 16px;

    .price-per-session-icon {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      min-width: 40px;
      height: 40px;
      margin-right: 16px;

      svg {
        color: $primary-80;
      }
    }

    .price-per-session-info {
      flex: 1 0 auto;
      @include font_P1_reg($primary-80);

      span {
        @include font_Sum1($primary-80);
      }

      span.dash-space {
        font-weight: 400 !important;
        margin: 0 10px;
      }

      @media (max-width: 420px) {
        span {
          @include font_Sum4($primary-80);
        }
      }
    }
  }

  @media (max-width: 420px) {
    &--price-per-session {
      .price-per-session-icon {
        min-width: 24px;
        height: 24px;

        .react-custom-icon.icon-size-xll {
          width: 24px !important;
          height: 24px !important;;

          svg {
            width: 24px !important;;
            height: 24px !important;;
          }
        }
      }
    }
  }
}
