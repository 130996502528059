@import "src/styles/variables";

.history-list {
  display: flex;
  flex-direction: column;
  padding: 24px;
  height: 100%;
  box-sizing: border-box;
  background-color: $white;

  @media (max-width: $mediaWidthMD) {
    padding: 24px 16px;
  }

  &--body {
    display: flex;
    flex-direction: column-reverse;
    height: 100%;
    overflow-y: auto;
    padding-right: 10px;

    .body-item {
      margin-top: 32px;

      &--today {
        @include font_P4_reg($primary-80);
        display: flex;
        white-space: nowrap;
        align-items: center;
        width: 100%;
        margin: 32px 0 16px 0;

        &:before, &:after {
          content: '';
          width: 100%;
          height: 1px;
          background-color: $primary-15;
        }

        &:before {
          margin-right: 14px;
        }

        &:after {
          margin-left: 14px;
        }
      }
    }

    .info-message {
      margin-top: 16px;
      max-width: 504px;
      overflow: hidden;
      margin-left: auto;
      margin-right: 48px;

      .info-message-text {
        word-break: break-word;
        white-space: break-spaces;
      }
    }
  }

  &--empty {
    flex: 1 0 auto;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    .empty-img {
      display: inline-flex;
      width: 100%;
      max-width: 500px;
      min-width: 250px;

      img {
        display: inline-flex;
        width: 100%;
        max-height: 500px;
      }
    }

    .empty-subtitle {
      @include font_sh_1();
      margin-top: 24px;
      text-align: center;
      word-break: break-word;
    }

    @media (max-width: $mediaWidthMD) {
      justify-content: flex-start;

      .empty-subtitle {
        text-align: left;
      }
    }
  }
}
